<script setup lang="ts">
import { Navigation, Grid, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import type { Swiper as SwiperTypes, SwiperOptions } from "swiper/types";
import type {
  SliderPartnersPressItem,
  SliderVerbatimItem,
  SliderBenefitsItem,
} from "~/models/SliderItem";
import type { JobsCard } from "~/models/JobsCard";
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";

const { isDesktop } = useDevice();
const { rt } = useI18n();
const {
  itemsSlider,
  bullets,
  autoplay,
  smoothMotion,
  loop,
  slideNumber,
  slideNumberDesk,
  speed,
  type,
  navigation,
  spaceBetweenDesk,
  delay,
} = defineProps<{
  itemsSlider: (
    | SliderPartnersPressItem
    | SliderVerbatimItem
    | SliderBenefitsItem
    | JobsCard
  )[];
  bullets?: boolean;
  autoplay?: boolean;
  smoothMotion?: boolean;
  loop?: boolean;
  slideNumber?: number;
  slideNumberDesk?: number;
  speed?: number;
  type?: string;
  navigation?: boolean;
  spaceBetweenDesk?: number;
  i18n?: boolean;
  customBullet?: string;
  navigationNegative?: boolean;
  delay?: number;
}>();
const modules = [Navigation, Grid, Pagination, Autoplay];
const slider = ref<null | SwiperTypes>(null);

const onSwiper = (swiper: SwiperTypes) => {
  slider.value = swiper as SwiperTypes;
};

defineExpose({
  slider,
});

const swiperOptions = computed(() => {
  const options: SwiperOptions = {
    wrapperClass: "extra-slider",
    modules,
    autoHeight: false,
    spaceBetween: 120,
    breakpoints: {
      768: {
        slidesPerView: slideNumberDesk,
        spaceBetween: spaceBetweenDesk || 0,
      },
    },
    slidesPerView: slideNumber || undefined,
    speed: speed || undefined,
    loop: loop || false,
    pagination: bullets
      ? {
          clickable: true,
        }
      : undefined,
    navigation: navigation ? { nextEl: ".arrow-right", prevEl: ".arrow-left" } : false,
    autoplay: autoplay ? { delay: delay ?? 0 } : undefined,
  };
  return options;
});

const displayNavigation = computed(() => {
  return navigation && isDesktop && itemsSlider.length > 1;
});
</script>

<template>
  <Swiper
    v-bind="swiperOptions"
    :class="{ 'smooth-motion': smoothMotion, [type]: type }"
    :lazy="true"
    @swiper="onSwiper"
  >
    <button
      v-if="displayNavigation"
      class="arrow-left arrow"
      :class="{ 'regular-nav': navigationNegative }"
      role="button"
      aria-label="Précédent"
    >
      &nbsp;
    </button>
    <swiper-slide v-for="(currentSlide, index) in itemsSlider" :key="index">
      <slot
        :url="currentSlide.url ?? undefined"
        :src="currentSlide.src"
        :alt="currentSlide.alt"
        :type="type"
        :quote="currentSlide.quote"
        :logo-src="currentSlide.logo?.url"
        :logo-alt="currentSlide.logo_alt"
        :photo-src="currentSlide.photo?.url"
        :photo-alt="currentSlide.photo_alt"
        :author="currentSlide.author"
        :value="i18n && currentSlide['@VALUE'] ? rt(currentSlide['@VALUE']) : undefined"
        :suffix="
          i18n && currentSlide['@SUFFIX'] ? rt(currentSlide['@SUFFIX']) : undefined
        "
        :desc="i18n ? rt(currentSlide['@DESC']) : undefined"
        :picto="currentSlide['PICTO'] ? currentSlide['PICTO'] : undefined"
        :data="currentSlide"
      />
    </swiper-slide>
    <button
      v-if="displayNavigation"
      class="arrow-right arrow"
      :class="{ 'regular-nav': navigationNegative }"
      role="button"
      aria-label="Suivant"
    >
      &nbsp;
    </button>
  </Swiper>
</template>

<style lang="scss" scoped>
.swiper-slider-item {
  &-image {
    max-width: 130px !important;
    @include mq(desktop) {
      max-width: 100px !important;
    }
  }
}
.arrow {
  display: block;
  width: 53px;
  height: 53px;
  background: url("/img/arrow-slider-left.svg") no-repeat;
  position: absolute;
  top: calc(50% - 25px);
  background-size: 90%;
  z-index: 1;
  &:hover {
    background: url("/img/arrow-slider-left-hover.svg") no-repeat;
    background-size: 90%;
  }
  &-left {
    left: -65px;
  }
  &-right {
    right: -65px;
    background: url("/img/arrow-slider-right.svg") no-repeat;
    background-size: 90%;
    &:hover {
      background: url("/img/arrow-slider-right-hover.svg") no-repeat;
      background-size: 90%;
    }
  }
  &.regular-nav {
    &.arrow-left {
      left: var(--showcase-size-300);
      top: calc(var(--showcase-size-450) * 2);
    }
    &.arrow-right {
      right: var(--showcase-size-200);
      top: calc(var(--showcase-size-450) * 2);
    }
  }
}
</style>
